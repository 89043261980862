<template>
    <BT-Blade-Items
        addBladeName="journey-archive"
        bladeName="journey-archives"
        :bladesData="bladesData"
        canSearchLocal
        :headers="[
            { text: 'Name', value: 'journeyName', title: 1 },
            { text: 'Started On', value: 'startedOn', textFilter: 'toShortDateAndTime', subtitle: 1 },
            { text: 'Ended On', value: 'endedOn', textFilter: 'toShortDateAndTime', subtitle: 2 }]"
        hideActions
        navigation="journey-archives"
        title="Journey Archives"
        useServerPagination />
</template>

<script>
export default {
    name: 'Journey-Archives',
    props: {
        bladesData: null
    }
}
</script>